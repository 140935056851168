@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.contentLayout{
    padding: 0 8.4rem 2.5rem 8.4rem;

    @include respond-below(md){
        padding: 0 1rem 1rem 1rem;
    }

    @include respond-below-portrait(lg){
        padding: 0 1rem 1rem 1rem;
    }
}