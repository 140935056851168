@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/theme/variables.scss";
@import "../../../styles/utils/responsive.scss";

$marginTop: 2.625rem;
$marginTopXS: 2rem;
$subNavMarginLeft:0.625rem;

.overflow {
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background: color("navbar");
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20%;
    min-width: 20rem;
    z-index: 2;

    @include respond-below(xs) {
        width: 100%;
    }

    .button {
        padding-top: $marginTop;
        padding-left: 2rem;

        @include respond-above(xs) {
            display: none !important;
        }

        @include respond-below(xs) {
            padding-top: $marginTopXS;
        }
    }
}

.innerContainer {
    width: 100%;
    padding: $marginTop 2.625rem 2.625rem 2.625rem;

    @include respond-below(sm) {
        width: 80%;
        justify-content: flex-start;
    }

    @include respond-below(xs) {
        padding-top: $marginTopXS;
    }
}

.logoContainer {
    display: flex;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
}

.itemsContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto; // mobile scroll

    .listContainer {
        display: flex;
        flex-direction: column;
        margin-top: 5rem;

        .item {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 2.7rem;
        }

        a {
            text-decoration: none;
            color: black;
            font-weight: 900;
            font-size: 1rem;
            line-height: 1.188rem;
            word-break: break-all;
        }

        .colapseDiv {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.7rem;

            .subNav {
                display: flex;
                flex-direction: column;
                padding-left: $subNavMarginLeft;

                a {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.188rem;
                    margin-top: 1.25rem;
                }
            }
        }
    }

    .text{
        padding-left: 0.5rem;
        text-decoration: none;
        color: black;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.188rem;
        word-break: break-all;
     }
}

.closable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .leftDiv{
        display: flex;
        align-items: center;
    }
    .rotate {
        transform: scale(-1, -1);
        cursor: pointer;
    }
}


.languageIcon{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.languageDiv {
    padding-left: $subNavMarginLeft;

    .languageRow{
        display:flex;
        flex-direction: row;
        margin-top: 1rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.188rem;

        &:hover label {
            text-shadow: 3px 2px 4px rgba(184, 184, 184, 0.6);
        }
    }

    input {
        cursor: pointer;
    }

    label{
        padding-left: 0.5rem;
        cursor: pointer;
    }

    .languageIso {
        text-transform: uppercase;
    }
}

