@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

$buttonsHeight: 40px;

.innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 1rem;
    z-index: 800;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hiddenImage {
    // width: 100%;
    // height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    color: transparent;
    padding: 0;
    display: block;
    max-width: 90%;
    max-height: calc(90% - 50px);
    aspect-ratio: 16/9;
    visibility: hidden;
}

// .canvasImage {
//     // This allows to have a dynamic size when resizing
//     // position: absolute;
// }

.buttonsContainer {
    width: 95%;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: $buttonsHeight;
    // position: absolute;

    .buttons {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.buttonsCenter {
            justify-content: center;
        }

        &.buttonsRight {
            justify-content: right;
        }
    }

    .buttonSpacer {
        margin: 0 0.5rem;
        // opacity: 0.18;
    }
}

.dropdown {
    transform: translateX(0) translateY(calc(-100% - #{$buttonsHeight})) scale(1);
}

.imageCropContainer {
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
}
