@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";


.container {
    background-color: color('wildSand');
    position: relative;
    display: flex;
    height: 100%;
}


.contentContainer {
    flex: 1;
    overflow: auto;
}

.overflowCon{
    overflow: hidden;
}