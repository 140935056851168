@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.input {
    width: 100%;
    background: #ffffff;
    border-radius: 0.625rem;
    height: 2.5rem;
    margin-top: 0.4rem;
    font-size: 1rem;
    padding-left:8px
}

.noWarning {
    border: 1px solid color('mercury');

    &:focus {
        outline: none;
        border-color: color('frenchGray');
    }
}

.warning {
    border: 1px solid color('warningYellow');

    &:focus {
        outline: none;
        border-color: color('warningYellow');
    }
}

.disabled {
    background-color: color('whisper');
    opacity: 1;
}

.invalidEmailMessage {
    font-size: 0.8rem;
    color: color('warningYellow');
}