@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.tabHeader {
    margin-right: 1rem;

    .div {
        font-size: 0.9rem;
        color: black;
        font-weight: 900;
        display: flex;
        padding: 0.625rem 1.25rem 0.625rem 1.25rem;
        border-radius: 6.25rem;
        cursor: pointer;    
    }

    .active {
        background: black;
        color: #fff;
    }
}

.requiredLabel {
    color: color('warningYellow');
    margin-left: 0.4rem;
}

.reducedPaddingRight{
    padding-right:0.3rem !important
}
.counter{
    background: black;
    color: white;
    margin-left: 0.5rem;
    font-size: 9px;
    background: radial-gradient(circle , black 50%, transparent calc(50% + 1px));
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter::after {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    content: attr(data-text);
    padding: 0 0.55rem;
    z-index: 10;
}
.activeCounter {
    background: radial-gradient(circle , white 50%, transparent calc(50% + 1px));
    color: black;
}