@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";
.photoDiv {
    display: flex;
    width: 100%;
    height: 16rem;
    justify-content: flex-end;
    align-items: center;

    @include respond-below(sm) {
        justify-content: center;
    }
}
