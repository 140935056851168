

.image {
    height: 2.188rem;
    width: 2.188rem;
    border-radius: 50%;
}

.doubleRowColumn{
    display: flex;
    flex-direction: column;
}


.optionsIcon {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}