@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.containerAdd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-weight: 900;
}

.containerInputNew {
    margin: 1rem 0;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
}

.containerItem {
    padding-top: 2rem;
}

.noteInfos {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0.5rem;
}

.noteContainer {
    border: 1px solid color('mercury');
    border-radius: 10px;
    background-color: color('white');
    padding: 0.5rem 1rem;
}

.noteContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
}

.actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #EFEFEF;
    border-radius: 30px;
    cursor: pointer;
    margin-left: 1rem;
}

.noteText {
    margin-bottom: 1rem;

    p {
        margin-top: 0;
    }

    img {
        max-width: 100%;
    }
}

.noItemsText {
    padding: 0.5rem;
    text-align: center;
}

.summernote {
    background-color: #ffffff;
}
