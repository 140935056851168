@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.imageColumn {
    display: flex;
    justify-content: center;
}

.image {
    max-height: 3rem;
    max-width: 6rem;
    object-fit: contain;
}
