.image {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}

.optionsIcon {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}