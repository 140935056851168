@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.commentHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-weight: bold;
    }
}

.container {
    display: flex;
    flex-direction: column;
    background-color: color('white');
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-top: 2.7rem;
}

.comment {
    margin-top: 0.5rem;
    border-bottom: 0.063rem solid #e6e6e6;
}

.comment:last-child {
    border-bottom: none;
}

.infoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.buttonsContainer {
    display: flex;
}

.info_text {
    font-size: 14px;
    color: #AFAFAF;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #EFEFEF;
    border-radius: 30px;
    cursor: pointer;
}


.withoutInfo {
    font-weight: bold;
    text-align: center;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.commentContainer {
    outline-color: black;
    outline-width: 2px;
}

.messageError {
    display: flex;
    align-items: flex-start;
    color: color('warningYellow');
    font-size: 0.8rem;
    width: 100%;
    margin: 0.1rem 0 0 0.5rem;
}
