@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

$pointsize: 2rem;

.container  {
    position: absolute;
}

.point {
    transition: border-color 250ms ease-in-out, opacity 250ms ease-in-out, visibility 250ms ease-in-out;
    // transform: translateX(-1rem) translateY(-1rem) scale(0.999);
    padding: 0;
    width: $pointsize;
    height: $pointsize;
    border: 2px solid transparent;
    background: rgba(17, 17, 17, 0.2);
    border-radius: 64px;
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        right: -0.5rem;
        bottom: -0.5rem;
        display: block;
    }

    .background {
        content: "";
        transition: transform 250ms ease-in-out;
        position: relative;
        display: block;
        background: rgb(255, 255, 255);
        box-shadow: 0 1px 4px rgba(17, 17, 17, 0.55);
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 64px;
        text-align: center;
    }

    &:hover,
    &.active {
        border-color: rgba(var(--colour-static-white, 255, 255, 255), 0.5);
        background: rgba(var(--colour-static-black, 17, 17, 17), 0.4);

        .background {
            transform: scale(0.667);
        }
    }

    &.unclickable {
        cursor: not-allowed;
    }
}

.tooltip {
    position: absolute;
    background-color: #fff;
    padding: 0.8rem;
    border-radius: 1rem;
    visibility: hidden;
    z-index: 10;
    box-shadow: 0 1px 2px rgba(17, 17, 17, 0.55);
    max-width: 400px;

    @include respond-below(sm) {
        max-width: 200px;
    }

    &.leftBottomPosition {
        border-top-right-radius: 0;
        top: $pointsize;
        transform: translateX(-100%) translateY(0) scale(1);
    }

    &.rightBottomPosition {
        border-top-left-radius: 0;
        top: $pointsize;
        left: $pointsize;
    }

    &.leftTopPosition {
        border-bottom-right-radius: 0;
        transform: translateX(-100%) translateY(calc(-100% + #{$pointsize})) scale(1);
        top: -$pointsize;
    }

    &.rightTopPosition {
        border-bottom-left-radius: 0;
        transform: translateX(0) translateY(calc(-100% + #{$pointsize})) scale(1);
        left: $pointsize;
        top: -$pointsize;
    }
}
