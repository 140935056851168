@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.iconPinterest {
    top: 10px;
    left: 10px;
    position: absolute;
}

.card{
    position: relative;

    @include respond-below(sm) {
        .previewOverlay {
            display: flex;
        }
    }

    @include respond-above(sm) {
        &:hover .previewOverlay {
            display: flex;
        }
    }
}

.previewOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.8);
    align-items: center;
    justify-content: center;
    display: none;
    border-radius: 16px;
    
}

.linkButton {
    background-color: #fff;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    position: absolute;
}