@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.inputGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.input {
    font-size: 1rem;
    @include fontWeight('light');
    @include placeholderColor(color('frenchGray'));
    outline: 0;
    border-radius: 0.625rem 0 0 0.625rem;
    position: relative;
    flex: 1 1 auto;
    border: 1px solid color('mercury');
    height: 2.5rem;
    padding-right: 0.5rem;

    &:focus {
        outline: none;
        border-color: color('frenchGray');
    }

    &:focus~.inputGroupAppend>.inputGroupText {
        border-color: color('frenchGray');
    }

}

.disabled {
    background-color: color('whisper');
    opacity: 1;
}

.inputGroupAppend {
    margin-left: -1px;
    display: flex;
    align-self: stretch;
}

.inputGroupText {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
    font-size: 1rem;
    color: color('black');
    text-align: center;
    white-space: nowrap;
    background-color: color('athensGray');
    @include fontWeight('bold');
    border-radius: 0 0.625rem 0.625rem 0;
    border: 1px solid color('mercury');
}