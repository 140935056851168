@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    margin-bottom: 0.4rem;
}

.input {
    margin: 0;
}

.requiredLabel {
    color: color('warningYellow');
}
