@import "../../../../styles/utils/responsive.scss";

.buttonsDiv {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include respond-below(xs) {
        justify-content: center;
    }
}