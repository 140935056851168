@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.inputContainer {
    display: flex;
    width: 100%;
    align-items: center;
    background: #333333;
    border-radius: 20px;
    justify-content: flex-end;
    padding-left: 0.5rem;

    .inputContent {
        background: #333333;
        border: unset;
        width: 100%;
        color: color('white');
        justify-content: start;

        &::placeholder {
            color: #787878;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

    }

    .containerImage {
        display: flex;
        justify-content: center;
        width: 49px;
        height: 33px;
        background: #FFFFFF;
        border-radius: 20px;
        cursor: pointer;

        .image {
            width: 1rem;
        }
    }
}