.contentInput {
    font-family: 'Inter', sans-serif !important;
    color: black;
    width: 100%;
    font-size: 16px;
    font-weight: 900;
    min-height: 1.5rem;
    
    border:none;
    outline-width: 0;
    resize: none;
    overflow: hidden;


    &:focus{
        outline-color: black;
        outline-width: 2px;
    }
}

.contentInput::placeholder{
    opacity: 1;
    color: black;
    font-size: 16px;
    font-weight: 900;
}

.contentInput:focus::placeholder {
    color: transparent;
}

.contentAreaInput {
    font-family: 'Inter', sans-serif !important;
    color: black;
    width: 100%;
    font-size: 14px;
    min-height: 5rem;
    font-weight: 400;
    
    border:none;
    outline-width: 0;
    resize: none;
    overflow: hidden;


    &:focus{
        outline-color: black;
        outline-width: 2px;
    }
}

.warning {
    border: 1px solid color('warningYellow');

    &:focus {
        outline: none;
        border-color: color('warningYellow');
    }
}

.disabled {
    background-color: color('whisper');
    opacity: 1;
}
