@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.mainDiv {
    margin-top: 2.5rem;

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: auto auto;
        grid-column-gap: 1.875rem;
        grid-row-gap: 1.875rem;

        @include respond-below(sm){
            grid-template-columns: 2fr;
        }

        .inspirationCard {
            aspect-ratio: 16 / 9;


                .editFavorite {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    top: 0.5rem;
                    right: 2.5rem;
                }
            .title {
                font-weight: 900;
                font-size: 1rem;
                line-height: 1.188rem;
                color: color("black");
                margin-top: 0.625rem;
                word-break: break-all;
            }

            .description {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: color("black");
                word-break: break-all;
            }
        }
    }

    .span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.563rem;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.188rem;
        color: #000;
        margin-bottom: 1.3rem;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .justifyCenter {
        display: flex;
        justify-content: center;
    }
}
