@import "../../../../styles/utils/functions.scss";
@import "../../../../styles/utils/mixins.scss";
@import "../../../../styles/utils/responsive.scss";

.container {
    margin: 0.3rem 0;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0.2rem 0;

    .title {
        flex: 1;
        font-weight: bold;
        font-size: 1rem;
    }

    .icon {
        font-size: 18;
    }
}

.listContainer {
    padding-left: 1rem;
}

.listIsRootContainer {
    padding-left: 0;
}

.requiredLabel {
    color: color('warningYellow');
}
