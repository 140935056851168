@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: column;

    .configContainer {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 50vh;
    }

    .operationsContainer {
        min-width: 54px;
        display: flex;
        justify-content: center;
        margin: 0 1rem 0 1rem;

        .multiButton {
            display: flex;
            flex-direction: column;

        button {
            margin: 0;
            flex: 1;
        }

        button:first-child {
            border-radius: 8px 8px 0 0;
        }

        button:last-child {
            border-radius: 0 0 8px 8px;
        }
      }
    }

    @include respond-below(lg) {
        .configContainer {
            flex-direction: column;
        }

        .rulesContainer,
        .usersContainer,
        .operationsContainer,
        .rolesContainer {
            flex: 1;
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 0.8rem;
}

.rulesContainer {
    flex: 3;
    margin-right: 1rem;
}

.usersContainer {
    flex: 5;

    .userInfo {
        flex: 1;
        display: flex;
        flex-direction: column;

        .name {
            flex-grow: 1;
        }

        .email {
            font-size: 80%;
        }
    }
}

.rolesContainer {
    flex: 3;
}

.striped {
    background-color: color("gallery");
}
