@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.photoDiv {
    width: 100%;
    padding-top: 2rem;

    @include respond-below(sm) {
        width: 50%;
        padding-top: 1rem;
        margin: auto;
    }
    
    @include respond-below(xs) {
        width: 100%;
    }
}
