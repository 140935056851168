
.image {
    height: 2.188rem;
    width: 2.188rem;
    border-radius: 50%;
    background: #d9d9d9;
}

.optionsIcon {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}