@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.buttonIconFavorite {
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    margin-right: 0.4rem;
    background: rgba(17, 17, 17, 0.2);

    &:last-child {
        margin-right: 0;
    }

    &.buttonIconEdit {
        padding: 0rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    @include respond-below(sm) {
        padding: 0.2rem;
        width: 2.4rem;
        height: 2.4rem;
    }
}