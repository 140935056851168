@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.outsideContainer {
    @include respond-below(sm) {
        margin-bottom: 50vh;
    }
}

.container {
    position: fixed;
    background-color: color('white');
    top: 0;
    right: 0;
    height: 100vh;
    width: 29%;
    padding: 6rem 2rem;
    box-shadow: -0.1em 0 1em color('frenchGray');
    z-index: 200;

    @include respond-below(lg){
        bottom: 0 !important;
        right: 0!important;
    }
}

.containerMobile {
    position: fixed;
    background-color: color('white');
    bottom: 0;
    right: 0;
    height: 50vh;
    width: 100%;
    padding: 2rem 1rem;
    box-shadow: -0.1em 0 1em color('frenchGray');
    overflow: scroll;
    overflow-x: hidden;
}

.inlineContainer {
    display: flex;
    align-items: center;
}

.iconBack {
    margin-right: 1rem;
    cursor: pointer;
}

.newPointIcon {
    background-color: #fff;
    padding-right: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    font-size: 16px;
}

.buttonsContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}
