@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;

    .tab {
        margin-right: 1rem;
        cursor: pointer;
        padding: 0.625rem 1.25rem 0.625rem 1.25rem;
        border-radius: 6.25rem;
        font-weight: 900;
        
        @include respond-below(sm){
            margin-top: 0.5rem;
            padding: 0.4rem 0.2rem 0.4rem 0.2rem;
        }

        &.active {
            border-bottom: 0;
            background: black;
            color: #fff;
        }

        .invalid {
            color: color('danger');
            font-size: 0.75rem;
            margin: 0 0.25rem 0.25rem 0;
        }

        .languageDefault {
            color: color('frenchGray');
            font-size: 1rem;
            margin: 0 0.25rem 0.25rem 0;
        }
    }
}