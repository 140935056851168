@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.mainDiv {
    margin-top:1rem;

    .subDiv {
        flex-direction: column;
        align-items: center;
        height: 100%;

        @include respond-below(sm) {
           margin-bottom: 2rem;
        }
    }
    
    .label{
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

}
.containerButtons {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
    cursor: pointer;
}
.image{
    display: flex;
    padding: 1rem;
    border: 1px solid color('mercury');
}