@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

$iconSize: 1rem;
$directionalIconSize: 2rem;

.container {
    position: relative;
    margin-top: 1.4rem;
    margin-bottom: 2rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.imagesGrid {
    display: grid;
    grid-template-areas:
        "l r"
        "l r"
        "l r";
    grid-template-columns: 3fr 1fr;
    // grid-gap: 10px;

    .editableImage {
        aspect-ratio: 16 / 9;
        grid-area: l;
        // animation: fadeIn 2s;
    }

    .smallImage {
        position: relative;
        cursor: pointer;
        aspect-ratio: 16/9;
        padding-left: 15px;
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: 14px;
        }
    }

    .editableImageFadeIn {
        width: 100%;
        height: 100%;
    }
}

.directionalButtonTop {
    height: $directionalIconSize;
    width: $directionalIconSize;
    border-radius: 50%;
    background-color: color('white');
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    left: calc(50% - #{$directionalIconSize/2});

    .chevronDown {
        height: $iconSize;
        width: $iconSize;
        transform: scale(-1, -1);
        cursor: pointer;
    }
}

.directionalButtonBottom {
    height: $directionalIconSize;
    width: $directionalIconSize;
    border-radius: 50%;
    background-color: color('white');
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -50px;
    left: calc(50% - #{$directionalIconSize/2});

    .chevronDown {
        height: $iconSize;
        width: $iconSize;
        cursor: pointer;
    }
}

.mobileMainImage {
    aspect-ratio: 16 / 9;
}

.mobileListItem {
    // height: 12.5rem;
    width: 100%;
}

.swiperContainer {

    .mobileSwiper {
        margin-top: 1rem;
        z-index: 0;
        height: 100%;
        margin-bottom: 0.5rem;
    }

    :global {
        .swiper-pagination-clickable {
            text-align: center;
        }
    
        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            background-color: grey;
            position: relative;
            top: 2px;
            left: 2px;  
           
        }

        .swiper-pagination-bullet-active {
            background-color: black;
        }
    }

    .mobileEditableImageSlide {
        aspect-ratio: 16/9;
    }
}

.warning {
    border: 1px solid #FFBF00;
}

.cursorDefault {
    cursor: default !important;
}

.smallImageUploaderIcon {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
}

.mobileUploaderIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.noImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: color('fileInputBackground');
    height: 100%;
}
